import logo from '../assets/worldLeavesLogo.png';
import "./style/logo.css";

const Logo = () => {
    return (
        <div className="logo-container">
            <h1 className="logo-text">World</h1>
            <img src={logo} alt="logo" className="logo-img" />
            <h1 className="logo-text">Leaves</h1>
        </div>
    );
}

export default Logo;