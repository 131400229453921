import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Vortex } from "react-loader-spinner";
import "./style/homePage.css";
import Background from "../background/background";
import Logo from "../logo/logo";

const apiGatewayDomain = process.env.REACT_APP_API_URL;
const resourcePath_inquiry = "inquiry";

const Home = () => {
  const [responseData, setResponseData] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessResponse, setIsSuccessResponse] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(false);
  const [errorResponseMessage, setErrorResponseMessage] = useState(null);
  const [getStartedClicked, setGetStartedClicked] = useState(false);
  const [showForm, setShowForm] = useState(true);

  const [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const submittedTextStyle =
    windowDimensions.width > windowDimensions.height
      ? { fontSize: "5vh" }
      : { fontSize: "6.5vw" };

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!checkNameValidity() || !checkInquiryValidity()) {
      return;
    }
    const form = e.target.elements;
    setIsLoading(true);
    await sendInquiry(form.name.value, form.email.value, form.inquiry.value);
  };

  const sendInquiry = async (name, email, inquiry) => {
    setResponseData(null);
    setResponseStatus(null);
    setErrorResponseMessage(null);
    setIsSuccessResponse(false);
    setIsErrorResponse(false);
    setIsLoading(true);

    const request = {
      name,
      email,
      inquiry,
    };
    console.log("request:", request);
    await fetch(apiGatewayDomain + resourcePath_inquiry, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    })
      .then(async (response) => {
        const data = await response.json();
        setResponseData(data);
        setResponseStatus(response.status);
      })
      .catch((error) => {
        setErrorResponseMessage(error.message);
        setIsErrorResponse(true);
        setIsSuccessResponse(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (responseStatus === 200) {
      setIsLoading(false);
      setIsSuccessResponse(true);
      setIsErrorResponse(false);
      setShowForm(false);
    } else if (responseStatus !== null) {
      setIsErrorResponse(true);
      setIsSuccessResponse(false);
      setErrorResponseMessage(responseData.message);
      setIsLoading(false);
    }
  }, [responseStatus]);

  const checkEmailValidity = () => {
    const emailInput = document.getElementById("email");
    if (emailInput.value === "") {
      emailInput.setCustomValidity("Email cannot be blank");
    } else if (emailInput.value.indexOf("@") === -1) {
      emailInput.setCustomValidity("Email must contain an @ symbol");
    }
    return emailInput.reportValidity();
  };

  const checkNameValidity = () => {
    const nameInput = document.getElementById("name");
    if (nameInput.value === "") {
      nameInput.setCustomValidity("Name cannot be blank");
    } else {
      nameInput.setCustomValidity("");
    }
    return nameInput.reportValidity();
  };

  const checkInquiryValidity = () => {
    const inquiryInput = document.getElementById("inquiry");
    if (inquiryInput.value === "") {
      inquiryInput.setCustomValidity("Inquiry cannot be blank");
    } else {
      inquiryInput.setCustomValidity("");
    }
    return inquiryInput.reportValidity();
  };

  const resetAllValidity = () => {
    document.getElementById("email").setCustomValidity("");
    document.getElementById("name").setCustomValidity("");
    document.getElementById("inquiry").setCustomValidity("");
  };

  return (
    <div className="homePage-container">
      <Helmet>
        <title>World Leaves</title>
      </Helmet>
      <Background />
      <div className="logo">
        <Logo />
      </div>

      {showForm && (
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div
              className="form-page"
              style={
                getStartedClicked
                  ? {
                      transform: "translate(-100%, -50%)",
                      opacity: 0,
                      pointerEvents: "none",
                    }
                  : { transform: "translate(-50%, -50%)", opacity: 1 }
              }
            >
              <input
                type="text"
                id="email"
                placeholder="Enter your email"
                className="email-input"
                autoComplete="email"
                onChange={resetAllValidity}
              />
              <div className="form-btn-area">
                <button
                  className="form-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    // check email validity here, continue only if valid
                    if (checkEmailValidity()) {
                      setErrorResponseMessage(null);
                      setIsErrorResponse(false);
                      setIsSuccessResponse(false);
                      setGetStartedClicked(true);
                    } else {
                      setGetStartedClicked(false);
                    }
                  }}
                >
                  Get Started
                </button>
              </div>
            </div>

            <div
              className="form-page"
              style={
                getStartedClicked
                  ? { transform: "translate(-50%, -50%)", opacity: 1 }
                  : {
                      transform: "translate(0%, -50%)",
                      opacity: 0,
                      pointerEvents: "none",
                    }
              }
            >
              <input
                type="text"
                id="name"
                placeholder="Name"
                className="name-input"
                autoComplete="name"
                onChange={resetAllValidity}
              />
              <textarea
                type="text-area"
                id="inquiry"
                placeholder="Inquiry"
                className="inquiry-input"
                onChange={resetAllValidity}
              />
              {isLoading ? (
                <div className="form-btn-area">
                  <Vortex color="#ffffff" size={30} />
                </div>
              ) : (
                <div className="form-btn-area">
                  <button
                    className={`form-btn ${"small"}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setGetStartedClicked(false);
                      setIsErrorResponse(false);
                      setIsSuccessResponse(false);
                    }}
                  >
                    &#x2190;
                  </button>
                  <input
                    type="submit"
                    className="form-btn"
                    value="Send Response"
                  />
                </div>
              )}
              <div className="form-response-area">
                <div className="form-response-error">
                  <p
                    className="form-response-error-text"
                    style={{ opacity: isErrorResponse ? 1 : 0 }}
                  >
                    {errorResponseMessage}
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}

      {isSuccessResponse && (
        <div className="inquiry-success-response-page">
          <p style={submittedTextStyle}>
            Thank you for your inquiry! Keep an eye on your inbox, we will get
            back to you as soon as possible.
          </p>
          <div className="form-btn-area">
            <button
              className="form-btn"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/";
              }}
            >
              Submit Another Inquiry
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
