import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Amplify } from "aws-amplify";
import config from "./amplifyconfiguration.json";
import "./App.css";
import Home from "./components/homePage/homePage";

Amplify.configure(config);

const App = () => {
  return (
    <div className="app-container">
      <div className="app-background-color"></div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
