import React, { useEffect, useState } from "react";
import background from "../assets/MNTN.png";
import "./style/background.css";

const Background = () => {
    const [windowDimensions, setWindowDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    const imgStyle = windowDimensions.width > windowDimensions.height ? 
        { width: "100%" } : 
        { height: "100%" };

    return (
        <div className="background-container">
            <img src={background} alt="background" className="background-img" style={imgStyle} />
        </div>
    );
}

export default Background;
